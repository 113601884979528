<div
	class="relative min-h-[300px] w-[90vw] bg-auto rounded-t-[40px] rounded-b-[15px] bg-center mx-auto overflow-hidden shadow-[0px_10px_15px_2px_#00000010]"
>
	<a
		class="min-h-[300px] w-[90vw] absolute"
		[href]="'../oferta/' + data.productId + '?' + getUrlParams()"
		(click)="handleButtonClick($event)"
	>
	</a>
	<ng-container *ngIf="data.image; else noImage">
		<img
			class="h-[300px]"
			height="300"
			width="500"
			[ngSrc]="data.image | optimizedImage"
			[alt]="data.title"
			loading="lazy"
		/>
	</ng-container>
	<ng-template #noImage>
		<p>Brak zdjęcia</p>
	</ng-template>
	<img
		[src]="data.promotionImage | optimizedImage"
		alt="Promocja"
		*ngIf="data.promotionImage"
		class="absolute top-7 w-1/3"
	/>
	<div
		class="bg-white px-4 py-2 bg-opacity-70 bottom-0 left-0 w-full text-sm flex justify-center flex-col text-mainColor"
	>
		<h1 class="text-left w-9/10 font-extrabold truncate md:whitespace-normal md:overflow-visible">
			{{ data.title }}
		</h1>
		<div class="flex flex-col justify-between w-9/10 w-full">
			<p class="font-medium">
				{{ data.date }}
			</p>
			<p class="text-mainColor font-extrabold text-xl text-right">
				od
				<span class="text-accentColor">{{ data.price | price }}</span>
			</p>
		</div>
	</div>
	<div class="absolute top-0 right-0 p-4 hidden">
		<i class="fa-regular fa-heart text-red text-2xl"></i>
	</div>
</div>
