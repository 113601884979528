import { ChangeDetectionStrategy, Component, inject, Input, input, OnInit, signal, ViewChild } from '@angular/core';
import { ITopSearchConfig, TopSearchMobileAdvancedConfig } from '@/new-top-search/top-search.config';
import { BehaviorSubject, combineLatest, filter, of, Subject, take, takeUntil } from 'rxjs';
import { OffersFacade } from '@/_store/offers/offers.facade';
import { BaseFilter, DEFAULT_PAGINATION_LIMIT, DEFAULT_PAGINATION_OFFSET } from '@/_store/offers/offers.types';
import {
	TopSearchDropdownItemMode,
	TopSearchFilterElType,
} from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.types';
import { createQueryParams } from '@/_store/offers/offers.utils';
import { Router } from '@angular/router';
import { DropdownComponent } from '@/common/dropdown/dropdown.component';
import { switchMap } from 'rxjs/operators';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { MobileDialogService } from '@/common/mobile-dialog/mobile-dialog.service';
import { TopSearchDropdownItemComponent } from '@/new-top-search/top-search-dropdown-item/top-search-dropdown-item.component';

@Component({
	selector: 'app-top-search-filter-items',
	templateUrl: './top-search-filter-items.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchFilterItemsComponent implements OnInit {
	readonly filters = input.required<ITopSearchConfig[]>();
	readonly searchValue = signal('');
	@ViewChild('dropdown') dropdown: DropdownComponent;

	@Input() itemMaxWidthPx = 400;

	isDropdownOpenArray: boolean[] = [];
	searchBarDropdownOpened = false;

	term$ = new BehaviorSubject<string>('');
	offersBySearchTerm$ = this.term$
		.asObservable()
		.pipe(switchMap((term) => (term.length > 2 ? this.offersFacade.searchOffersBySearchTerm(term) : of(null))));

	isMobile$ = this.generalFacade.isMobile$;

	readonly TopSearchFilterElType = TopSearchFilterElType;
	readonly TopSearchDropdownItemMode = TopSearchDropdownItemMode;

	private readonly urlParams$ = this.offersFacade.urlParams$;
	private readonly offset$ = this.offersFacade.offset$;
	private readonly router = inject(Router);
	private _valueChange = false;

	constructor(
		private readonly offersFacade: OffersFacade,
		private readonly generalFacade: GeneralDataFacade,
		private readonly mobileDialogService: MobileDialogService,
	) {}

	ngOnInit(): void {
		this.isDropdownOpenArray = new Array(this.filters().length).fill(false);
	}

	changeOpenState(isOpen: boolean, i: number): void {
		this.isDropdownOpenArray[i] = isOpen;
	}

	set valueChange(value: boolean) {
		this._valueChange = value;
	}

	termUpdated(term: string): void {
		this.term$.next(term);
	}

	chooseProduct(product: BaseFilter): void {
		this.router.navigate(['oferta', product.id]);
	}

	chooseLocation(location: BaseFilter): void {
		this.offersFacade.addLocationsFilterOption(location.id);
		this.goToOfferList();
	}

	fetchOffers(): void {
		this.offersFacade.updateOffset(DEFAULT_PAGINATION_OFFSET);
		combineLatest([this.offersFacade.urlParams$, this.offset$])
			.pipe(
				take(1),
				filter(() => this._valueChange),
			)
			.subscribe(([params, offset]) => {
				this.valueChange = false;
				this.offersFacade.clearOfferList();
				this.offersFacade.fetchOfferList(
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset}`,
					params + `&limit=${DEFAULT_PAGINATION_LIMIT}&offset=${offset + DEFAULT_PAGINATION_LIMIT}`,
				);
				this.offersFacade.updateQueryParams(params);
			});
	}

	search(): void {
		if (!this.searchValue().length) {
			return;
		}
		this.offersFacade.updateSearchFilter(this.searchValue());
		this.searchValue.set('');
		this.offersFacade.clearSelectedProducts();
		this.offersFacade.reloadOfferList();
		this.urlParams$.pipe(take(1)).subscribe((params) => {
			const queryParams = createQueryParams(params);
			this.router.navigate(['/wakacje-autokarem'], {
				queryParams,
			});
		});
	}

	openMobileModal(mode: TopSearchDropdownItemMode): void {
		const title = TopSearchMobileAdvancedConfig.find((config) => config.id === mode).text;
		const componentFactory = this.mobileDialogService.openDialogWithComponent<
			TopSearchDropdownItemComponent,
			{ mode: TopSearchDropdownItemMode }
		>(TopSearchDropdownItemComponent, { title, mode });
		const closed$ = new Subject<void>();

		componentFactory.instance.triggerClose.pipe(take(1)).subscribe(() => {
			this.mobileDialogService.close();
		});

		combineLatest([componentFactory.instance.valueChange, this.mobileDialogService.afterClose$])
			.pipe(takeUntil(closed$))
			.subscribe(([valueChange]) => {
				this.valueChange = valueChange;
				this.fetchOffers();
				closed$.next();
				closed$.complete();
			});
	}

	closeDropdown(): void {
		this.dropdown.closeDropdown();
	}

	private goToOfferList(): void {
		this.offersFacade.selected$.pipe(take(1)).subscribe(console.log);
	}
}
