<section
	#topSearchRef
	[ngClass]="{ '!fixed top-0': isSticky && (isDesktop$ | async) }"
	class="top-search w-full bg-[#FFFFFFA3] relative md:absolute mt-[60px] md:mt-0 md:z-50"
>
	<div class="mx-auto my-0 shadow-navi w-full relative flex justify-center flex-col">
		<article class="lg:w-[1440px] lg:mx-auto lg:flex items-center lg:mt-2.5 lg:mb-2.5">
			<div class="lg:flex lg:flex-wrap my-4 w-full gap-[15px]">
				<ng-container *ngIf="isDesktop$ | async; else mobileFilters">
					<app-top-search-filter-items
						class="w-full"
						[filters]="TopSearchMainConfig"
					></app-top-search-filter-items>
					<app-top-search-filter-items
						*ngIf="isOfferList && (isFavoriteRoute$ | async) === false"
						class="w-full"
						[filters]="TopSearchAdvancedConfig"
						[itemMaxWidthPx]="300"
					></app-top-search-filter-items>
				</ng-container>
				<ng-template #mobileFilters>
					<app-top-search-filter-items
						class="w-full"
						[filters]="
							isOfferList && (isFavoriteRoute$ | async) === false
								? TopSearchMobileAdvancedConfig
								: TopSearchMobileConfig
						"
					>
					</app-top-search-filter-items>
					<div (click)="clearAllFilters()" class="clear">
						<span class="material-symbols-outlined"> cancel </span>
						<p>wyczyść wszystko</p>
					</div>
				</ng-template>
			</div>
			@if (isDesktop$ | async) {
				<div class="flex justify-center md:block mb-4 md:mb-0">
					<button
						*ngIf="!isOfferList"
						class="bg-mainYellow py-2 tracking-wide md:ms-3 font-semibold text-white rounded-full h-fit w-52 hover:bg-[#e9a41a] transition-colors"
						(click)="goToOfferList()"
						data-testid="search-offers-btn"
					>
						Zobacz oferty
					</button>
				</div>
			}
		</article>
	</div>
</section>

@if (isMobile$ | async) {
	<div class="flex justify-center md:block mb-4 md:mb-0">
		<button
			*ngIf="!isOfferList"
			class="mobile-search-btn bg-mainYellow py-2 tracking-wide md:ms-3 font-semibold text-white rounded-[16px] h-fit w-60 hover:bg-[#e9a41a] transition-colors"
			(click)="goToOfferList()"
			data-testid="search-offers-btn"
		>
			Szukaj wycieczek
		</button>
	</div>
}
