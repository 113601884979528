import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	inject,
	Inject,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	viewChild,
} from '@angular/core';
import { OffersFacade } from '@/_store/offers/offers.facade';
import {
	TopSearchAdvancedConfig,
	TopSearchMainConfig,
	TopSearchMobileAdvancedConfig,
	TopSearchMobileConfig,
} from '@/new-top-search/top-search.config';
import { GeneralDataFacade } from '@/_store/general/general.facade';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { combineLatest, EMPTY, filter, fromEvent, of, Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { createQueryParams, setSelectedValueBaseOnParams } from '@/_store/offers/offers.utils';
import { Selected } from '@/_store/offers/offers.types';
import { deepCompare } from '@/_utils/objects/deep-compare';
import { map, switchMap } from 'rxjs/operators';
import { FavoriteService } from '@/common/favorite/favorite.service';
import { toObservable } from '@angular/core/rxjs-interop';

@Component({
	selector: 'app-new-top-search',
	templateUrl: './top-search.component.html',
	styleUrls: ['./top-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopSearchComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() isOfferList = false;
	isMobile$ = this.generalDataFacade.isMobile$;
	isDesktop$ = this.generalDataFacade.isMobile$.pipe(map((isMobile) => !isMobile));
	isSticky = false;

	readonly TopSearchMainConfig = TopSearchMainConfig;
	readonly TopSearchAdvancedConfig = TopSearchAdvancedConfig;
	readonly TopSearchMobileConfig = TopSearchMobileConfig;
	readonly TopSearchMobileAdvancedConfig = TopSearchMobileAdvancedConfig;
	readonly isFavoriteRoute$ = toObservable(this.favoriteService.isFavoriteRoute);
	readonly storedFavorites$ = toObservable(this.favoriteService.storedFavorites);

	private naviHeight: number = null;
	private readonly destroy$: Subject<void> = new Subject<void>();
	private readonly urlParams$ = this.offersFacade.urlParams$;
	private readonly activateRoute = inject(ActivatedRoute);
	private readonly topSearchElement = viewChild<ElementRef>('topSearchRef');

	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly offersFacade: OffersFacade,
		private readonly generalDataFacade: GeneralDataFacade,
		private readonly ngZone: NgZone,
		private readonly cdr: ChangeDetectorRef,
		private readonly router: Router,
		private readonly favoriteService: FavoriteService,
	) {}

	ngOnInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		combineLatest([
			this.offersFacade.filterOptions$,
			this.activateRoute.queryParams,
			this.offersFacade.offerFiltersAreEmpty$,
			this.offersFacade.selected$,
			this.isFavoriteRoute$,
			this.storedFavorites$,
		])
			.pipe(
				take(1),
				switchMap(([filterOptions, params, isEmpty, selected, isFavoriteRoute]) => {
					if (isEmpty) {
						this.offersFacade.fetchOfferFilter();
						return EMPTY;
					}
					const path = new URL(window.location.href).pathname;
					const match = path.match(/\/wakacje-autokarem\/[^?]*/);
					const result = match ? match[0] : null;
					const slashCount = result ? (result.match(/\//g) || []).length : 0;
					if (slashCount > 0) {
						return of();
					}
					const selectedFromParams: Selected = setSelectedValueBaseOnParams(filterOptions, params);
					return of({ filterOptions, params, selected, selectedFromParams, isFavoriteRoute });
				}),
				filter(({ selectedFromParams, selected, isFavoriteRoute }) =>
					isFavoriteRoute ? true : !deepCompare(selectedFromParams, selected),
				),
			)
			.subscribe(({ selectedFromParams, isFavoriteRoute }) => {
				if (isFavoriteRoute) {
					selectedFromParams.products = localStorage.getItem('favoriteOffers')
						? JSON.parse(localStorage.getItem('favoriteOffers'))
						: [];
				}
				this.offersFacade.updateSelectedOptions(selectedFromParams);
				this.offersFacade.reloadOfferList();
			});

		this.observeStickyTopSearch();
	}

	ngAfterViewInit(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		const navEl = this.document.querySelector('nav');
		if (navEl) {
			this.naviHeight = navEl.getBoundingClientRect().height;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	goToOfferList(): void {
		this.urlParams$.pipe(take(1)).subscribe((params) => {
			const queryParams = createQueryParams(params);
			this.router.navigate(['/wakacje-autokarem'], {
				queryParams,
			});
		});
	}

	clearAllFilters(): void {
		this.offersFacade.clearSelectedOptions();
		this.offersFacade.reloadOfferList();
	}

	private observeStickyTopSearch(): void {
		this.ngZone.runOutsideAngular(() => {
			fromEvent(window, 'scroll')
				.pipe(takeUntil(this.destroy$), withLatestFrom(this.isMobile$))
				.subscribe((value) => {
					const isMobile = value[1];
					if (isMobile) return;
					this.updateBeforeHeightBasedOnTargetPosition();
					const top = window.scrollY || this.document.documentElement.scrollTop;
					const previousStickyState = this.isSticky;
					this.isSticky = top > this.naviHeight;
					if (previousStickyState !== this.isSticky) {
						this.cdr.detectChanges();
					}
				});
		});
	}

	private updateBeforeHeightBasedOnTargetPosition(): void {
		if (isPlatformServer(this.platformId)) {
			return;
		}

		const targetElement = this.getTargetElement();
		if (!this.topSearchElement() || !targetElement) {
			return;
		}

		const topSearchElement = this.topSearchElement().nativeElement;
		const targetBottom = targetElement.getBoundingClientRect().bottom;

		const { bottom: topSearchBottom, height: topSearchHeight } = topSearchElement.getBoundingClientRect();

		const percentage = this.calculateHeightPercentage(topSearchBottom, targetBottom, topSearchHeight);

		topSearchElement.style.setProperty('--before-height', `${percentage}%`);
	}

	private getTargetElement(): Element | null {
		return this.document.querySelector('app-static-banner') ?? this.document.querySelector('app-banner-carousel');
	}

	private calculateHeightPercentage(topSearchBottom: number, targetBottom: number, topSearchHeight: number): number {
		if (topSearchHeight === 0) {
			return 0;
		}
		return ((topSearchBottom - targetBottom) / topSearchHeight) * 100;
	}
}
